<template>
    <div class="search-container">
        <div class="search-content">
            <div class="search-categories-filters">
                <Categories :categories="menuCategories" />
                <Filter v-for="filterResult in filterItems" :filter="filterResult" cleanFilters="" :key="filterResult" />
                <div v-show="filtersLoaded" class="clear-filter-container">
                    <button @click="onCleanFiltersClicked" class="clear-filter-button">
                        Filter zurücksetzen
                    </button>
                </div>
                <!-- Preistip-Display-Left-below-filters -->
                <div id="adsense-container"></div>
                <div id="google-adword-container-1" class="google-adword-container"></div>
                <div id="google-adword-container-2" class="google-adword-container"></div>
            </div>
            <div class="search-results">
                <div class="offers-results-count">{{ totalSearchResults }} Treffer</div>
                <SearchResults :searchResults="searchItems" :showMoreButton="showMoreButton"
                    :totalItems="totalSearchResults" />
                <div id="google-adword-container-3" class="google-adword-container"></div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Categories from "@/components/categories/Categories.vue";
import Filter from "@/components/filter/Filter.vue";
import SearchResults from "@/components/search/SearchResults.vue";
import Network from "@/scripts/networks.js";
import Session from "@/scripts/session.js";

export default {
    props: ["categories"],
    components: {
        Categories,
        Filter,
        SearchResults,
    },
    data() {
        return {
            menuCategories: this.categories,

            isMobile: false,

            currentOffset: 0,
            pageSize: 100,
            showMoreButton: false,
            totalSearchResults: 0,
            sort: "auto",

            isOnSale: 0,
            isDeal: 0,
            brands: [],
            shops: [],
            paymentMethods: [],
            minPrice: -1,
            maxPrice: -1,

            searchItems: {},
            filterItems: {},

            filtersLoaded: false,
        };
    },
    methods: {
        async onAmazonClicked() {
            let categoryTitle = this.$route.query["categoryTitle"]
            let query = this.$route.query["query"]
            let url = ""
            if (query) {
                url = "https://www.amazon.de/s?k=" + query + "&camp=1638&creative=6742&linkCode=ur2&tag=preistip0e-21"
            } else if (categoryTitle) {
                url = "https://www.amazon.de/s?k=" + categoryTitle + "&camp=1638&creative=6742&linkCode=ur2&tag=preistip0e-21"
            }
            window.open(url)
            let resp = this.onAffiliateClicked(url, "Amazon")
        },

        async onEbayClicked() {
            let categoryTitle = this.$route.query["categoryTitle"]
            let query = this.$route.query["query"]
            let url = ""
            if (query) {
                url = "http://rover.ebay.com/rover/1/707-53477-19255-0/1?icep_ff3=9&pub=5575032274&toolid=10001&campid=5337506642&customid=&icep_uq=" + query + "&icep_sellerId=&icep_ex_kw=&icep_sortBy=12&icep_catId=&icep_minPrice=&icep_maxPrice=&ipn=psmain&icep_vectorid=229487&kwid=902099&mtid=824&kw=lg"
            } else if (categoryTitle) {
                url = "http://rover.ebay.com/rover/1/707-53477-19255-0/1?icep_ff3=9&pub=5575032274&toolid=10001&campid=5337506642&customid=&icep_uq=" + categoryTitle + "&icep_sellerId=&icep_ex_kw=&icep_sortBy=12&icep_catId=&icep_minPrice=&icep_maxPrice=&ipn=psmain&icep_vectorid=229487&kwid=902099&mtid=824&kw=lg"
            }
            window.open(url)
            let resp = this.onAffiliateClicked(url, "Ebay")
        },

        async onAffiliateClicked(url, affiliate) {
            let categoryId = ""
            let queryCategoryId = this.$route.query["categoryId"]
            if (queryCategoryId) {
                categoryId = queryCategoryId
            }
            const body = {
                clickOutUrl: url,
                categoryId: categoryId,
                sessionId: Session.getSession(),
                trafficSource: affiliate,
                publisher: "affiliate"
            };
            return await Network.post(process.env.VUE_APP_API_BASE_URL + 'tracking/offer/affiliate', body);
        },

        onCleanFiltersClicked() {
            this.currentOffset = 0;
            this.refreshFilters();
            this.updateSearchResuls(true, false);
        },

        onShowMoreClicked() {
            this.currentOffset = this.currentOffset + this.pageSize;
            this.updateSearchResuls(false, true);
        },

        onFiltersChanged(filterType, checkedFilters, priceFilter) {
            if (filterType == "is_deal") {
                if (checkedFilters.length == 0) {
                    this.isDeal = 0;
                } else {
                    this.isDeal = 1;
                }
            } else if (filterType == "is_on_sale") {
                if (checkedFilters.length == 0) {
                    this.isOnSale = 0;
                } else {
                    this.isOnSale = 1;
                }
            } else if (filterType == "brand") {
                this.brands = checkedFilters;
            } else if (filterType == "shop") {
                this.shops = checkedFilters;
            } else if (filterType == "payment_method") {
                this.paymentMethods = checkedFilters;
            }
            if (priceFilter != null) {
                this.minPrice = priceFilter[0];
                this.maxPrice = priceFilter[1];
            }
            this.currentOffset = 0;
            this.updateSearchResuls(false);
        },

        updateSearchResuls(loadFilters, appendResults = false) {
            if (loadFilters) {
                this.refreshFilters();
            }
            const searchUrl = this.buildSearchURL();
            const filterUrl = this.buildFilterURL();
            const query = this.$route.query["query"];
            const categoryId = this.$route.query["categoryId"];

            if (query != null && query != undefined) {
                searchUrl.searchParams.append("query", query);
                filterUrl.searchParams.append("query", query);
                this.search(searchUrl.href, appendResults);
                if (loadFilters) {
                    this.getFilters(filterUrl.href);
                }
                if (appendResults == false) {
                    this.addGoogleAdwardsSearch(query)
                }
            } else if (categoryId != null && categoryId != undefined) {
                searchUrl.searchParams.append("categoryId", categoryId);
                filterUrl.searchParams.append("categoryId", categoryId);
                this.search(searchUrl.href, appendResults);
                if (loadFilters) {
                    this.getFilters(filterUrl.href);
                }
                if (appendResults == false) {
                    this.addGoogleAdwardsSearch(this.$route.query["categoryTitle"])
                }
            }
        },

        async search(url, appendResults = false) {
            const searchJsonResponse = await Network.get(url);
            var searchItems = searchJsonResponse["items"];
            var total = searchJsonResponse["searchInfo"].total;
            this.totalSearchResults = parseInt(total).toLocaleString('de-DE');
            this.updateLoadMoreButton(total);
            if (appendResults) {
                this.searchItems.push(...searchItems);
            } else {
                this.searchItems = null;
                this.searchItems = searchItems;
            }
        },

        async getFilters(url) {
            const filterJsonResponse = await Network.get(url);
            this.filterItems = filterJsonResponse["filters"];
            this.filtersLoaded = true;
        },

        buildSearchURL() {
            return this.buildURL(process.env.VUE_APP_API_BASE_URL + "search");
        },

        buildFilterURL() {
            return this.buildURL(process.env.VUE_APP_API_BASE_URL + "search/filters");
        },

        buildURL(searchUrl) {
            const url = new URL(searchUrl);

            url.searchParams.append("offset", this.currentOffset);
            url.searchParams.append("pageSize", this.pageSize);
            url.searchParams.append("sort", this.sort);

            if (this.isOnSale) {
                url.searchParams.append("isOnSale", this.isOnSale);
            }
            if (this.isDeal) {
                url.searchParams.append("isDeal", this.isDeal);
            }

            if (this.brands != null && this.brands.length > 0) {
                url.searchParams.append("brands", this.brands);
            }

            if (this.shops != null && this.shops.length > 0) {
                url.searchParams.append("shops", this.shops);
            }

            if (this.paymentMethods != null && this.paymentMethods.length > 0) {
                url.searchParams.append("paymentMethods", this.paymentMethods);
            }

            if (this.minPrice >= 0 && this.maxPrice > 0) {
                url.searchParams.append("minPrice", this.minPrice);
                url.searchParams.append("maxPrice", this.maxPrice);
            }
            return url;
        },

        refreshFilters() {
            this.isOnSale = 0;
            this.isDeal = 0;
            this.brands = [];
            this.shops = [];
            this.paymentMethods = [];
            this.minPrice = -1;
            this.maxPrice = -1;
        },

        updateLoadMoreButton(total) {
            if (total < this.pageSize) {
                this.showMoreButton = false;
            } else if (this.currentOffset + this.pageSize < total) {
                this.showMoreButton = true;
                this.currentOffset + this.pageSize;
            } else {
                this.showMoreButton = false;
            }
        },

        addGoogleAdwardsSearch(query) {
            var pageOptions = {
                "pubId": "ca-pub-4833053544773030", // Make sure that this is the correct client ID!
                "styleId": "7353148678",
                "adsafe": "high"
            };

            var adblock1 = {
                'container': 'google-adword-container-1',
            };

            var adblock2 = {
                'container': 'google-adword-container-2',
            };

            var adblock3 = {
                'container': 'google-adword-container-3',
            };

            pageOptions.query = query;
            _googCsa('ads', pageOptions, adblock1, adblock2, adblock3);
        },

        loadGoogleAdsenseStatic() {
            let adContainer = document.getElementById('adsense-container'); 

            let ins = document.createElement('ins');
            ins.className = 'adsbygoogle';
            ins.style.display = 'block';
            ins.setAttribute('data-ad-client', 'ca-pub-4833053544773030');
            ins.setAttribute('data-ad-slot', '2094852979');
            ins.setAttribute('data-ad-format', 'auto');
            ins.setAttribute('data-full-width-responsive', 'true');
            adContainer.appendChild(ins);
            (adsbygoogle = window.adsbygoogle || []).push({});
        }
    },
    watch: {
        "$route.query.categoryId": {
            handler: function () {
                this.updateSearchResuls(true);
            },
            deep: true,
            immediate: true,
        },
        categories: {
            handler(newVal, _) {
                this.menuCategories = newVal;
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.loadGoogleAdsenseStatic();
    },
};
</script>
  
<style>
.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-content {
    max-width: var(--container-width);
    flex: 1;
    padding-left: 1em;
    padding-right: 1em;
    display: grid;
    gap: 1em;
}

.search-categories-filters {
    grid-column-start: 1;
    grid-column-end: 2;
}

.search-results {
    grid-column-start: 2;
    grid-column-end: 6;
}

.clear-filter-container {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.clear-filter-button {
    border-radius: 6px;
    padding: 0.5em 1em;
    border: 0px;
    background-color: rgba(var(--primary-color-rgba), 0.4);
    transition: background-color 0.4s;
    color: var(--white);
    font-weight: var(--fw-700);
    font-size: 20px;
    font-family: var(--ff-primary);
    font-variant: small-caps;
    letter-spacing: 0.02rem;
}

.clear-filter-button:hover {
    background-color: var(--primary-color);
    cursor: pointer;
}

.offers-results-count {
    font-weight: var(--fw-500);
    font-size: var(--fs-400);
    color: var(--black);
    padding-top: 1em;
    padding-bottom: 1em;
}

.google-adword-container {
    margin-top: 1em;
    width: 100%;
}

/* Width >= 1920px */
@media (width >=120em) {
    .search-content {
        grid-template-columns: repeat(5, 2.4fr);
    }
}

/* Width >= 1536px and width < 1920px */
@media (width >=96em) and (width < 120em) {
    .search-content {
        grid-template-columns: repeat(5, 2.4fr);
    }
}

/* Width >= 1280px and width < 1536px */
@media (width >=80em) and (width < 96em) {
    .search-content {
        grid-template-columns: repeat(4, 3fr);
    }
}

/* Width >= 1140px and width < 1280px */
@media (width >=71.25em) and (width < 80em) {
    .search-content {
        grid-template-columns: repeat(4, 3fr);
    }
}

/* Width >= 1024px and width < 1140px */
@media (width >=64em) and (width < 71.25em) {
    .search-content {
        grid-template-columns: repeat(3, 4fr);
    }
}

/* Width >= 896px and width < 1024px */
@media (width >=56em) and (width < 64em) {
    .search-content {
        grid-template-columns: repeat(3, 4fr);
    }
}

/* Width >= 768px and width < 896px */
@media (width >=48em) and (width < 56em) {
    .search-content {
        grid-template-columns: repeat(2, 6fr);
    }

    .search-categories-filters {
        display: none;
    }

    .search-results {
        grid-column-start: 1;
        grid-column-end: 6;
    }
}

/* Width >= 568px and width < 768px */
@media (width >=35.5em) and (width < 48em) {
    .search-content {
        grid-template-columns: repeat(2, 6fr);
    }

    .search-categories-filters {
        display: none;
    }

    .search-results {
        grid-column-start: 1;
        grid-column-end: 6;
    }
}

/* Width < 568px */
@media (width < 35.5em) {
    .search-content {
        grid-template-columns: repeat(1, 12fr);
    }

    .search-categories-filters {
        display: none;
    }

    .search-results {
        grid-column-start: 1;
        grid-column-end: 6;
    }
}
</style>